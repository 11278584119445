var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-main',[(!_vm.remoteServiceValid)?_c('v-alert',{attrs:{"border":"left","color":"red","dense":"","outlined":"","prominent":"","type":"error"}},[_c('div',{staticClass:"title"},[_vm._v("API Server Not Working")]),_c('div',[_vm._v(" "+_vm._s(_vm.remoteServiceBaseUrl)+" ")])]):_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"4"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var validate = ref.validate;
var reset = ref.reset;
return [_c('v-form',[_c('div',{staticClass:"display-1 mb-3 text-center"},[_c('b',[_vm._v("SWIFT")]),_vm._v(" Driver")]),_c('v-card',{staticClass:"elevation-12"},[_c('v-card-text',[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Login","name":"login","prepend-icon":"mdi-account","type":"text","error-messages":errors},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login($event)}},model:{value:(_vm.loginModel.Username),callback:function ($$v) {_vm.$set(_vm.loginModel, "Username", $$v)},expression:"loginModel.Username"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"id":"password","label":"Password","name":"password","prepend-icon":"mdi-lock","type":"password","error-messages":errors},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login($event)}},model:{value:(_vm.loginModel.Password),callback:function ($$v) {_vm.$set(_vm.loginModel, "Password", $$v)},expression:"loginModel.Password"}})]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.login}},[_vm._v("Login")])],1)],1)],1)]}}])})],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.overlay}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }