








































































import Vue from "vue";
import Util from "./../../lib/util";

export default Vue.extend({
  name: "Login",
  components: {},
  data: () => ({
    overlay: false,
    loginModel: {
      Username: "",
      Password: "",
    },
    remoteServiceValid: Util.remoteServiceValid,
    remoteServiceBaseUrl: Util.remoteServiceBaseUrl,
  }),
  methods: {
    async login() {
      const form: any = this.$refs.observer;
      form.validate().then(async (valid: boolean) => {
        if (!valid) return;
        this.overlay = true;
        try {
          await this.$store.dispatch({
            type: "app/login",
            data: this.loginModel,
          });
          location.reload();
        } finally {
          this.overlay = false;
        }
      });
    },
  },
});
